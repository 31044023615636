export default class Prediction {
  constructor () {
    this.setInitialValue()
  }

  setInitialValue () {
    this.prediction = {
      amount: '',
      result: null,
      user_id: null,
      user: { name: '', phone: '' },
      participant_id: null,
      participant: { raceName: '', number: '' }
    }
  }

  fillData (data) {
    if (data) {
      this.prediction.amount = data.amount ? data.amount : ''
      this.prediction.result = data.result ? data.result : null
      this.prediction.user_id = data.user_id ? data.user_id : null
      this.prediction.user.name = data.user.name ? data.user.name : ''
      this.prediction.user.phone = data.user.phone ? data.user.phone : ''
      this.prediction.participant.raceName = data.participant.raceName ? data.participant.raceName : ''
      this.prediction.participant.number = data.participant.number ? data.participant.number : ''
    } else {
      this.setInitialValue()
    }
  }
}
