import api from '@/axios'

export default {
  findAll () {
    return api().get('prediction')
  },
  findOne (id) {
    return api().get(`prediction/${id}`)
  }
}
