<template>
  <div>
    <b-card>
      <ValidationObserver ref="prediction" v-slot="{ handleSubmit }">
        <b-form class="form-top p-4" @submit.prevent="handleSubmit(formSubmit)">
        <b-row>
          <b-col md="6">
            <input-form v-model="prediction.prediction.amount" :name="$t('global.amount')" :label="$t('global.amount')" :disabled="disableStatus" />
          </b-col>
          <b-col md="6">
            <input-form v-model="prediction.prediction.result" :name="$t('global.result')" :label="$t('global.result')" :disabled="disableStatus" />
          </b-col>

          <b-col md="6">
            <input-form v-model="prediction.prediction.user.name" :name="$t('clients.item')" :label="$t('clients.item')" :disabled="disableStatus" />
          </b-col>
          <b-col md="6">
            <input-form v-model="prediction.prediction.user.phone" :name="$t('global.phone')" :label="$t('global.phone')" :disabled="disableStatus" />
          </b-col>

          <b-col md="6">
            <input-form v-model="prediction.prediction.participant.raceName" :name="$t('races.item')" :label="$t('races.item')" :disabled="disableStatus" />
          </b-col>
          <b-col md="6">
            <input-form v-model="prediction.prediction.participant.number" :name="$t('participants.item')" :label="$t('participants.item')" :disabled="disableStatus" />
          </b-col>
        </b-row>
      </b-form>
      </ValidationObserver>
    </b-card>

    <b-card v-if="currentPage == itemPages.view">
      <client-transactions :predictionId="id" :titleStatus="true" />
    </b-card>
  </div>
</template>
<script>
import { core } from '../../../../config/pluginInit'
import predictionServices from '../services/predictions'
import Prediction from '../models/Prediction'
import ClientTransactions from '../../../transactions/clientTransactions/views/ClientTransactions.vue'
import moduleItemMixin from '../../../../Mixins/moduleItemMixin'

export default {
  mixins: [moduleItemMixin],
  components: { ClientTransactions },
  data () {
    return {
      prediction: new Prediction()
    }
  },
  mounted () {
    core.index()
  },
  created () {
    if (this.id) {
      predictionServices.findOne(this.id).then(response => {
        this.prediction.fillData(response.data.data)
        this.loading = false
      })
    } else {
      this.loading = false
    }
  }
}
</script>
